import React from "react";
import Link from "next/link";
import { Grid } from "@mui/material";
import { getAssets } from "@unisearch/helpers";
import Image from "next/legacy/image";
import styles from "./unisearch_student.module.scss";

import img_student from "../../../../public/assets/images/home/unisearch-student.webp";

export const UnisearchStudent = () => {
  return (
    <section className={styles.section_unisearch_student}>
      <div className="container">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item sm={12} md={6}>
            <div className={styles.uni_wrapper}>
              <img
                className={styles.oval}
                src={getAssets("assets/images/home/oval.webp")}
                alt="Oval"
                title="Oval"
              />
              <img
                className={styles.square}
                src={getAssets("assets/images/home/square.webp")}
                alt="Square"
                title="Square"
              />
              <div className={styles.image_wrapper}>
                <Image
                  layout="responsive"
                  src={img_student}
                  alt="Unisearch study international"
                  title="Unisearch study international"
                  placeholder="blur"
                />
              </div>
            </div>
          </Grid>
          <Grid item sm={12} md={6} className={styles.student_right}>
            <div className={`${styles.title_wrapper}`}>
              <h2 className={`${styles.title} fw400`}>
                UniSearch for
                <br />
                <span className="mark">Student</span>
              </h2>
              <p className={`${styles.sub_title}`}>
                From shortlisting and applying to the right programs and
                universities through AI-driven profile-matching, to getting visa
                application assistance, finding accommodation and more, we
                combine technological and interpersonal support to make studying
                abroad smooth sailing
              </p>
              <Link legacyBehavior href="/student">
                <a className="btn btn-secondary btn-bg">Student</a>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default UnisearchStudent;
